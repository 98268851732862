<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/perguntas-sesmt/new"
        editPath="/perguntas-sesmt/"
        :expander="false"
        subtitle="Pergunta SESMT"
        :actionItems="actionItems"
        @onToggleMenu="onToggleMenu"
        tooltip="Cadastro Pergunta SESMT"
        permission="gestaosesmt_cadastros_questionario_sesmt_perguntas"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.PERGUNTA_SESMT"
        descricao="FILTRO PADRÃO PERGUNTAS SESMT"
        nomeTelaDoManual="perguntas-sesmt-list"
        origemHistoricoAcao="/pergunta-sesmt"
    >
        <template #columns>
            <Column field="id" header="Cód." :sortable="true" sortField="pergunta_sesmt.id"></Column>
            <Column field="descricao" header="Pergunta" :sortable="true" sortField="pergunta_sesmt.descricao"></Column>
            <Column field="tipo" header="Tipo" :sortable="true" sortField="pergunta_sesmt.tipo">
                <template #body="slotProps">
                    {{ tipos.find((tipo) => tipo.value === slotProps.data.tipo)?.label }}
                </template>
            </Column>
            <Column field="opcoes" header="Opções">
                <template #body="slotProps">
                    {{
                        slotProps.data.opcoes &&
                        slotProps.data.opcoes
                            .map((opcao, index) => {
                                return ` ${index + 1}- ${opcao}`;
                            })
                            .toString()
                            .replace(',', ';')
                    }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="pergunta_sesmt.createdAt" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps.data?.userCreated?.name }} </small>
                </template>
            </Column>

            <Column field="updatedAt" :sortable="true" sortField="pergunta_sesmt.updatedAt" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data?.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps.data?.userUpdated?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
    <ConfirmDialog :closable="false" :draggable="false" :group="`${this.record.id}`" style="width: 600px">
        <template #message="slotProps">
            <div v-if="Array.isArray(slotProps.message.message)" class="flex flex-column">
                <ul>
                    <li v-for="(mensagem, index) in slotProps.message.message" :key="index">
                        {{ mensagem }}
                    </li>
                </ul>
                <div class="flex flex-row align-items-center gap-3 mt-3">
                    <i class="pi pi-exclamation-triangle text-orange-500 text-4xl text-900"></i>
                    <p class="text-lg font-semibold">
                        A pergunta será inativada em todos os questionários listados acima juntamente com suas condicionais. Deseja prosseguir?
                    </p>
                </div>
            </div>
            <div v-else class="flex p-4">
                <p class="pl-2">{{ slotProps.message.message }}</p>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta';
import { getClientSesmt } from '../../services/http-sesmt';

export default {
    data() {
        return {
            service: null,
            record: {},
            tenantCustomers: [],
            tipos: EnumTipoPergunta,
            permission: 'gestaosesmt_cadastros_questionario_sesmt_perguntas',
            actionItems: [
                {
                    label: 'Alterar',
                    disabled: () => !this.$checkPermission(this.permission + ':editar') && this.permission,
                    icon: 'pi pi-pencil',
                    command: () => this.$refs.grid.editRecord(this.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    disabled: () => !this.$checkPermission(this.permission + ':excluir') && this.permission,
                    command: () => this.confirmDeleteRecord()
                }
            ]
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new SesmtService('/pergunta-sesmt');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        async onClickDeleteRecord(record) {
            if (!record) {
                return;
            }

            try {
                const { data } = await this.service.remove(record.id);
                this.$toast.add({
                    severity: 'success',
                    summary: data,
                    life: 3000
                });
                this.load();
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 5000
                });
            }
        },
        async confirmDeleteRecord() {
            try {
                let message = 'Tem certeza que deseja excluir esta pergunta?';
                const { data } = await getClientSesmt().get(`pergunta-sesmt/validar-vinculos/${this.record.id}`);

                if (data.possuiVinculo) {
                    message = data.mensagens;
                }

                this.$confirm.require({
                    group: `${this.record.id}`,
                    header: 'Excluir pergunta?',
                    message: message,
                    acceptLabel: 'Excluir',
                    rejectLabel: 'Cancelar',
                    acceptClass: 'p-button-danger p-button-outlined',
                    accept: () => {
                        if (data.possuiVinculo) {
                            return this.desativarVinculos();
                        }
                        this.onClickDeleteRecord(this.record);
                    }
                });
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + error,
                    life: 5000
                });
            }
        },

        async desativarVinculos() {
            try {
                await getClientSesmt().post(`pergunta-sesmt/inativar-todos-os-vinculos/${this.record.id}`);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro excluído com sucesso!',
                    life: 3000
                });
                this.load();
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 5000
                });
            }
        }
    }
};
</script>
